





import React, { useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { createType } from '../../http/productAPI';

const CreateType = ({ show, onHide }) => {
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const addType = async () => {
        setLoading(true);
        setError(null);
        try {
            await createType({ name: value });
            setValue('');
            onHide();
        } catch (e) {
            setError('Ошибка при создании категории');
            console.error('Error creating type:', e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить новую категорию
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        placeholder="Введите название категории"
                        isInvalid={!!error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Закрыть</Button>
                <Button
                    variant='outline-success'
                    onClick={addType}
                    disabled={!value || loading}
                >
                    {loading ? <Spinner animation="border" size="sm" /> : 'Добавить'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateType;
