


import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Carousel, Button, Modal, Form } from 'react-bootstrap';
import TypeBar from '../components/TypeBar';
import ProductList from '../components/ProductList';
import Pages from '../components/Pages';
import ExampleCarouselImage from '../components/ExampleCarouselImage';
import ScrollToTopArrow from '../components/ScrollToTopArrow';
import SearchBar from '../components/SearchBar';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { fetchProducts, fetchTypes, fetchAds, deleteAds, updateAds, createAds } from '../http/productAPI';
import '../css/Shop.css';

// Import your images
import banner1 from '../photoNvabar/de3f63e2-9365-45a3-8245-b3a338825594.jpg';
import banner2 from '../photoNvabar/Banner.jpg';
import banner3 from '../photoNvabar/Group 102.jpg';

const Shop = observer(() => {
    const { product } = useContext(Context);
    const productSectionRef = useRef(null);  // Создаем реф

    // State hooks for ads and modal
    const [ads, setAds] = useState([]);
    const [selectedAd, setSelectedAd] = useState(null);
    const [adInfo, setAdInfo] = useState('');

    useEffect(() => {
        const loadData = async () => {
            try {
                const types = await fetchTypes();
                product.setTypes(types);

                // Устанавливаем категорию по умолчанию (например, первая категория)
                const defaultCategory = types[7];
                product.setSelectedType(defaultCategory);

                const initialLimit = 10;
                product.setLimit(initialLimit);
                const products = await fetchProducts(null, product.page, initialLimit);
                product.setProducts(products.rows);
                product.setTotalCount(products.count);

                // Fetch ads
                const adsData = await fetchAds();
                setAds(adsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        loadData();
    }, [product]);

    useEffect(() => {
        const loadProducts = async () => {
            try {
                const limit = product.limit;
                const typeId = product.selectedType?.id;  // используем выбранный тип
                const subtypeId = product.selectedSubtype?.id;
                const products = await fetchProducts(typeId, product.page, limit, subtypeId);
                product.setProducts(products.rows);
                product.setTotalCount(products.count);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        loadProducts();
    }, [product.page, product.selectedType, product.selectedSubtype, product.limit]);

    const handleSaveAd = async () => {
        try {
            if (selectedAd) {
                // Update ad
                await updateAds(selectedAd.id, { info: adInfo });
            } else {
                // Create new ad
                await createAds({ info: adInfo });
            }
            const adsData = await fetchAds();
            setAds(adsData);
            setSelectedAd(null);
            setAdInfo('');
        } catch (error) {
            console.error('Error saving ad:', error);
        }
    };

    const handleSearch = async (query) => {
        try {
            const limit = product.limit;
            // Устанавливаем typeId в null, чтобы искать по всем категориям
            const products = await fetchProducts(null, 1, limit, null, null, query);
            product.setProducts(products.rows);
            product.setTotalCount(products.count);
            product.setPage(1); // Reset to first page
        } catch (error) {
            console.error('Error searching products:', error);
        }
    };

    const handleThirdBannerClick = () => {
        if (productSectionRef.current) {
            productSectionRef.current.scrollIntoView({ behavior: 'smooth' });  // Прокрутка к продукции
        }
    };

    // Array of images for carousel
    const carouselImages = [
        { src: banner1, alt: 'Slide 1' },
        { src: banner2, alt: 'Slide 2' },
        { src: banner3, alt: 'Slide 3' }
    ];

    return (
        <Container fluid>
            <Row className='mt-2'>
                <Col md={12} className='carousel-wrapper'>
                    <Carousel>
                        {carouselImages.map((image, index) => (
                            <Carousel.Item 
                                key={index} 
                                interval={3000}
                                onClick={index === 2 ? handleThirdBannerClick : null}  // Добавляем обработчик клика для третьего баннера
                            >
                                <ExampleCarouselImage src={image.src} alt={image.alt} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
            
            <Row className='mt-2 shop-content' ref={productSectionRef}> {/* Устанавливаем реф на секцию */}
                <Col md={12} className="type-bar-wrapper">
                    <SearchBar style={{ marginBottom: '2px' }} onSearch={handleSearch} />
                    <TypeBar />
                </Col>
                <Col md={12} className="product-list-wrapper">
                    <div className="product-pages-container">
                        <ProductList />
                        <Pages />
                    </div>
                </Col>
            </Row>
            
            {selectedAd && (
                <Modal show={!!selectedAd} onHide={() => setSelectedAd(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedAd ? 'Редактировать объявление' : 'Добавить объявление'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Информация</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={adInfo}
                                onChange={(e) => setAdInfo(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSelectedAd(null)}>
                            Отмена
                        </Button>
                        <Button variant="primary" onClick={handleSaveAd}>
                            {selectedAd ? 'Сохранить изменения' : 'Добавить объявление'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <ScrollToTopArrow />
        </Container>
    );
});

export default Shop;
