export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SHOP_ROUTE = '/'
export const BASKET_ROUTE = '/basket'
export const PRODUCT_ROUTE = '/product'
export const REDIRACTION_ROUTE = '/redirection'
export const DELIVERYINFO_ROUTE = '/deliveryInfo'
export const PDPPOLICY_ROUTE = '/PersonalDataProcessingPolicy'
export const ADS_ROUTER = '/ads';
export const POA_ROUTE = 'PublicOfferAgreement'