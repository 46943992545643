

import React, { useContext, useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, Offcanvas, Dropdown, Badge } from 'react-bootstrap';
import { Context } from '../index.js';
import { observer } from "mobx-react-lite";
import logo from '../photoNvabar/Navigation icon/Head/Don Kulinar new.png';
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import { ADMIN_ROUTE, BASKET_ROUTE, DELIVERYINFO_ROUTE, LOGIN_ROUTE, SHOP_ROUTE } from '../utils/consts';
import { useCart } from '../components/CartContext';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/NavBar.css'; // Убедитесь, что путь верен
import { check } from '../http/userAPI';
import Cookies from 'js-cookie';
// Styled component for NavLink with hover effect
const StyledNavLink = styled(RouterNavLink)`
    color: white; /* Default text color */
    text-decoration: none; /* Remove underline */
    size: 30px
    &:hover {
        color: #ffc107; /* Text color on hover */
        text-decoration: underline; /* Underline on hover */
    }
`;
const StyledNavLinkMob = styled(RouterNavLink)`
    color: white; /* Default text color */
    text-decoration: none; /* Remove underline */
    font-size: 28px !important;
    &:hover {
        color: #ffc107; /* Text color on hover */
        text-decoration: underline; /* Underline on hover */
    }
`
const OffcanvasTitleMob = styled(RouterNavLink)`
    color: black; /* Default text color */
    text-decoration: none; /* Remove underline */
    font-size: 28px !important;
`
const StyledContainer = styled(Container)`
  @media only screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.img`
    cursor: pointer; /* Indicate that the logo is clickable */
`;

const CartDropdownMob = styled(Dropdown)`
    .dropdown-toggle {
        background: transparent;
        border: none;
        color: white;
        font-size: 16px;
        padding: 0;
        &:hover {
            background: transparent;
            color: #ffc107;
        }
    }

    .dropdown-menu {
        background: #343a40;
        border: none;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }

    .dropdown-item {
        color: white;
        &:hover {
            background: #495057;
        }
    }
`;




const NavBar = observer(() => {
    const { user } = useContext(Context);
    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showCartContent, setShowCartContent] = useState(false); // Для показа содержимого корзины на несколько секунд
    const { cartItems } = useCart();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const TOKEN_NAME = 'token';
    const REFRESH_TOKEN_NAME = 'refreshToken';
    const logOut = () => {
        user.setUser({});
        user.setIsAuth(false);
        Cookies.remove(TOKEN_NAME);
        Cookies.remove(REFRESH_TOKEN_NAME);
        window.location.reload(); 
    };

    
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await check();
                if (userData) {
                    user.setUser(userData);
                    user.setIsAuth(true);
                }
            } catch (error) {
                console.error('Ошибка при проверке аутентификации:', error);
                Cookies.remove(TOKEN_NAME);
                Cookies.remove(REFRESH_TOKEN_NAME);
                user.setIsAuth(false);
            }
        };

        if (Cookies.get(TOKEN_NAME)) {
            fetchUser();
        }
    }, [user]);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleLogoClick = () => {
        window.location.replace(SHOP_ROUTE); // Перенаправление с обновлением страницы
    };

    
   

    return (
        <>
        {isMobile ? (
            <>
                <Navbar sticky="top" bg="dark" variant="dark" className="py-4 navbar-dark">
                    <StyledContainer>
                        <Navbar.Brand>
                            <Logo
                                src={logo}
                                className="d-inline-block align-top logo"
                                alt="Don Kulinar"
                                onClick={handleLogoClick}
                            />
                        </Navbar.Brand>
                        <Button variant="primary" onClick={handleShow}>
                            <span className="navbar-toggler-icon"></span>
                        </Button>
                    </StyledContainer>
                </Navbar>
    
                <Offcanvas show={show} onHide={handleClose} placement="start">
                    <Offcanvas.Header closeButton>
                        <OffcanvasTitleMob>Меню</OffcanvasTitleMob>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-column">
                            <StyledNavLinkMob as={RouterNavLink} className="nav-link" onClick={handleClose} to={SHOP_ROUTE} >Главная</StyledNavLinkMob>
                            <StyledNavLinkMob  className="nav-link" onClick={handleClose} to={DELIVERYINFO_ROUTE}>Доставка</StyledNavLinkMob>
                            <StyledNavLinkMob className="nav-link mx-4"onClick={handleClose} to={BASKET_ROUTE}>Корзина</StyledNavLinkMob>
                            {user.IsAuth ? (
                            
                                <>
                                    {/* {user.role === 'ADMIN' && ( */}
                                    {user.role === "ADMIN" && (
                                        
                                        <StyledNavLinkMob className="nav-link" onClick={handleClose} to={ADMIN_ROUTE}>
                                            Админ
                                        </StyledNavLinkMob>
                                    )}
                                    <StyledNavLinkMob
                                        className="nav-link"
                                        onClick={() => {
                                            if (window.confirm('Вы уверены, что хотите выйти?')) {
                                                handleClose();
                                                logOut();
                                            }
                                        }}
                                    >
                                        Выйти
                                    </StyledNavLinkMob>
                                </>
                            ) : (
                                <StyledNavLinkMob className="nav-link" to={LOGIN_ROUTE} onClick={() => { user.setIsAuth(false); handleClose(); }}>Авторизация</StyledNavLinkMob>
                            )}
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        ) : (
            <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark" className="py-4 navbar-dark">
                <StyledContainer>
                    <Navbar.Brand>
                        <Logo
                            src={logo}
                            className="d-inline-block align-top logo"
                            alt="Don Kulinar"
                            onClick={handleLogoClick}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            
                            <Nav className="ms-auto">
                                <StyledNavLink className="nav-link mx-4" to={SHOP_ROUTE} >Главная</StyledNavLink>
                            </Nav>
                        
                            <StyledNavLink className="nav-link mx-4" to={DELIVERYINFO_ROUTE}>Доставка</StyledNavLink>
                            <StyledNavLink className="nav-link mx-4" to={BASKET_ROUTE}>Корзина</StyledNavLink>
                        </Nav>
                        {user.IsAuth ? (
                            <Nav className="ms-auto">
                                {user.role === "ADMIN" && (
                                        
                                        <StyledNavLink className="nav-link" onClick={handleClose} to={ADMIN_ROUTE}>
                                            Админ
                                        </StyledNavLink>
                                        
                                    )}
                                <StyledNavLink
                                    className="nav-link mx-4"
                                    onClick={() => {
                                        if (window.confirm('Вы уверены, что хотите выйти?')) {
                                            handleClose();
                                            logOut();
                                        }
                                    }}
                                >
                                    Выйти
                                </StyledNavLink>
                            </Nav>
                        ) : (
                            <Nav className="ms-auto">
                                <StyledNavLink className="nav-link mx-4" to={LOGIN_ROUTE} onClick={() => user.setIsAuth(false)}>Авторизация</StyledNavLink>
                            </Nav>
                        )}
                    </Navbar.Collapse>
                </StyledContainer>
            </Navbar>
        )}
    </>
        
    );
});

export default NavBar;

