import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Context } from "../index";
import { Pagination } from "react-bootstrap";
import '../css/Pages.css'
const Pages = observer(() => {
    const { product } = useContext(Context);

    const pageCount = Math.ceil(product.totalCount / product.limit);
    const pages = [];

    const visiblePages = 6; // Number of pages to show before and after the current page

    for (let i = 0; i < pageCount; i++) {
        pages.push(i + 1);
    }

    const handlePageClick = (page) => {
        product.setPage(page);
    };

    const renderPageNumbers = () => {
        const pageItems = [];
        const startPage = Math.max(product.page - visiblePages, 1);
        const endPage = Math.min(product.page + visiblePages, pageCount);

        // Add the first page
        if (startPage > 1) {
            pageItems.push(
                <Pagination.Item
                    key={1}
                    active={product.page === 1}
                    onClick={() => handlePageClick(1)}
                >
                    {1}
                </Pagination.Item>
            );

            if (startPage > 2) {
                pageItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        // Add range of pages
        for (let page = startPage; page <= endPage; page++) {
            pageItems.push(
                <Pagination.Item
                    key={page}
                    active={product.page === page}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        // Add the last page
        if (endPage < pageCount) {
            if (endPage < pageCount - 1) {
                pageItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }

            pageItems.push(
                <Pagination.Item
                    key={pageCount}
                    active={product.page === pageCount}
                    onClick={() => handlePageClick(pageCount)}
                >
                    {pageCount}
                </Pagination.Item>
            );
        }

        return pageItems;
    };

    return (
        <Pagination className="mt-3 pages">
            {renderPageNumbers()}
        </Pagination>
    );
});

export default Pages;
