import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CreateAds = ({ show, onHide, ad, onSave, onInfoChange }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{ad ? 'Редактировать объявление' : 'Создать объявление'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Информация объявления</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={ad ? ad.info : ''}
                        onChange={onInfoChange}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Отмена
                </Button>
                <Button variant="primary" onClick={onSave}>
                    {ad ? 'Сохранить изменения' : 'Создать'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateAds;
