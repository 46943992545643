import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import "../css/SearchBar.css"

const SearchBar = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        onSearch(query);
    };

    return (
        <Form onSubmit={handleSearch}>
            <div className="input-group" style={{ marginBottom: '5px' }}>
                <input
                    type="search"
                    className="form-control rounded"
                    placeholder="Поиск"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}  // Исправлено: перенесено из кнопки в инпут
                />
                <button type="submit" className="btn btn-outline-dark" data-mdb-ripple-init>
                    Найти
                </button>
            </div>
        </Form>
    );
};

export default SearchBar;
