    import React from 'react';
    import '../css/Footer.css';
    import VK from '../photoNvabar/Navigation icon/FooterIcon/Vkontakte.png';
    import { DELIVERYINFO_ROUTE, PDPPOLICY_ROUTE, POA_ROUTE } from '../utils/consts';
    import { useNavigate } from 'react-router-dom';

    const Footer = () => {
        const navigate = useNavigate();

        const handleNavigation = (route) => {
            
            navigate(route);
        };
        

        return (
            <footer className="footer bg-body-tertiary text-center text-lg-start">
                <div className="container p-4">
                    <div className="row justify-content-center align-items-start">
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
                            <h6 className="text-uppercase">Клиентам</h6>
                            <a 
                                className="footer-link" 
                                onClick={() => handleNavigation(DELIVERYINFO_ROUTE)}
                            >
                                Доставка
                            </a>
                            <br/>
                            <a className="footer-link" onClick={() => handleNavigation(PDPPOLICY_ROUTE)}>Политика обработки персональных данных</a>
                            <br/>
                            <a className="footer-link" onClick={() => handleNavigation(POA_ROUTE)}>Договор публичной оферты</a>
                        </div>  
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
                            <h6 className="text-uppercase">Адрес</h6>
                            <p>ул.Теплоэнергетиков 2</p>
                        
                            
                        </div>
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
                            <h6 className="text-uppercase num">Телефон</h6>
                            <a href="tel:+7954891265" className='num'>8 960 116 81 81</a>
                        </div>
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
                            <h6 className="text-uppercase">Время работы доставки</h6>
                            <p>с 10:00 до 21:30</p>
                        </div>
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0 social-media">
                            <h6 className="text-uppercase">Мы в социальных сетях</h6>
                            <a href="https://vk.com/donburger36" target="_blank"><img src={VK} alt="Vkontakte" className="Vkontakte" /></a>
                        </div>
                    </div>
                </div>
                
            </footer>
        );
    };

    export default Footer;
