// import React, { useState, useEffect, useContext } from 'react';
// import { Modal, Button, Image, Col, Row, Form } from 'react-bootstrap';
// import { CartContext } from '../components/CartContext';
// import { fetchOneProduct } from '../http/productAPI';
// import '../css/ProductPage.css'
// const ProductPage = ({ productId, show, onHide }) => {
//     const { addToCart } = useContext(CartContext);
//     const [product, setProduct] = useState({ info: [] });

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const productData = await fetchOneProduct(productId);
//                 if (productData) {
//                     setProduct(productData);
//                 }
//             } catch (error) {
//                 console.error('Failed to fetch product data:', error);
//             }
//         };

//         fetchData();
//     }, [productId]);

//     const handleAddToCart = () => {
//         addToCart(product);
//         onHide();
//     };

//     return (
//         <Modal show={show} onHide={onHide}>
//             <Modal.Body>
//                 <div className='d-flex justify-content-center'>
//                     {product.img && <Image className='imgModal' width={450} height={350} style={{ borderRadius: '5px 5px 0px 0px' }} src={process.env.REACT_APP_API_URL + product.img} />}
//                 </div>
//                 <Row style={{ padding: '0' }} className='coll'>
//                     <Col>
//                         <div>
//                             <h4 style={{ fontSize: '35px' }}>{product.name}</h4>
//                         </div>
//                         {product.info.map((info) => (
//                             <Row key={info.id}>
//                                 <div style={{marginLeft:'5px', width:'450px'}}>{info.description}</div>
//                             </Row>
//                         ))}
//                     </Col>
//                 </Row>
//             </Modal.Body>
//             <Modal.Footer>
//                 <div className='d-flex justify-content-start' style={{ flex: 1, marginLeft: '17px' }}>
//                     <div>Сумма: </div>
//                     <div>{product.price} руб.</div>
//                 </div>
                
                
//                 <Button variant="secondary" onClick={onHide}>
//                     Выйти
//                 </Button>
//                 <Button variant="primary" onClick={handleAddToCart}>
//                     Добавить в корзину
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

// export default ProductPage;


import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { CartContext } from '../components/CartContext';
import { fetchOneProduct } from '../http/productAPI';
import '../css/ProductPage.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import photoPlaceholder from '../photoNvabar/8bf4cea1-5f55-43ad-b3a2-4f3b07e9ccad.jpg'; // Заглушка

const ProductPage = ({ productId, show, onHide }) => {
    const { addToCart } = useContext(CartContext);
    const [product, setProduct] = useState({ info: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productData = await fetchOneProduct(productId);
                if (productData) {
                    setProduct(productData);
                }
            } catch (error) {
                console.error('Failed to fetch product data:', error);
            }
        };

        fetchData();
    }, [productId]);

    const handleAddToCart = () => {
        addToCart(product);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <div className='d-flex justify-content-center'>
                    {product.img && (
                        <LazyLoadImage
                            className="imgModal"
                            src={process.env.REACT_APP_API_URL + product.img} // Основное изображение
                            alt={product.name}
                            placeholderSrc={photoPlaceholder} // Заглушка
                            effect="opacity" // Плавное появление вместо блюра
                        />
                    )}
                </div>
                <Row style={{ padding: '0' }} className='coll'>
                    <Col>
                        <div>
                            <h4 style={{ fontSize: '35px' }}>{product.name}</h4>
                        </div>
                        {product.info.map((info) => (
                            <Row key={info.id}>
                                <div style={{ marginLeft: '5px', width: '450px' }}>{info.description}</div>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-start' style={{ flex: 1, marginLeft: '17px' }}>
                    <div>Сумма: </div>
                    <div>{product.price} руб.</div>
                </div>
                <Button variant="secondary" onClick={onHide}>
                    Выйти
                </Button>
                <Button variant="primary" onClick={handleAddToCart}>
                    Добавить в корзину
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProductPage;

