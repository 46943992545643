
// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useCart } from '../components/CartContext'; // Импортируем useCart из контекста корзины

// const Redirection = () => {
//     const navigate = useNavigate(); // Используем useNavigate для навигации
//     const { setCartItems } = useCart(); // Деструктурируем setCartItems из контекста корзины

//     useEffect(() => {
//         if (setCartItems) {
//             // Очищаем корзину при оформлении заказа
//             setCartItems([]);
//         } else {
//             console.error('setCartItems is not available in context');
//         }
        
//         // Перенаправление на страницу магазина через 3 секунды
//         const timer = setTimeout(() => {
//             navigate('/');
//         }, 3000);

//         return () => clearTimeout(timer); // Очистка таймера при размонтировании компонента
//     }, [navigate, setCartItems]);

//     return (
//         <div style={{ textAlign: 'center', marginTop: '50px' }}>
//             <h1>Заказ оформлен!</h1>
//             <p>Вы будете перенаправлены на страницу магазина через несколько секунд...</p>
//         </div>
//     );
// };

// export default Redirection;




import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../components/CartContext';

const Redirection = () => {
    const navigate = useNavigate();
    const { setCartItems } = useCart(); // Теперь setCartItems доступен

    useEffect(() => {
        if (setCartItems) {
            // Очищаем корзину при оформлении заказа
            setCartItems([]);
        } else {
            console.error('setCartItems is not available in context');
        }
        
        // Перенаправление на страницу магазина через 3 секунды
        const timer = setTimeout(() => {
            navigate('/');
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate, setCartItems]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Заказ оформлен!</h1>
            <p>Скоро с вами свяжеться человек для уточнения заказа</p>
        </div>
    );
};

export default Redirection;
