import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Modal, Form } from 'react-bootstrap';
import CreateProduct from '../components/modals/CreateProduct';
import CreateType from '../components/modals/CreateType';
import CreateAds from '../components/modals/CreateAds';
import {
    fetchProducts,
    fetchTypes,
    deleteProduct,
    updateProduct,
    updateProductImage,
    fetchAds,
    createAds,
    updateAds,
    deleteAds
} from '../http/productAPI';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import '../css/Admin.css';

const Admin = observer(() => {
    const { product } = useContext(Context);
    const [typeVisible, setTypeVisible] = useState(false);
    const [productVisible, setProductVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [adVisible, setAdVisible] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [selectedTypeId, setSelectedTypeId] = useState('');
    const [selectedStockStatus, setSelectedStockStatus] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [selectedAdId, setSelectedAdId] = useState(null);
    const [ads, setAds] = useState([]);
    const [adInfo, setAdInfo] = useState('');

    // Fetch types, products, and ads
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [types, products, adsData] = await Promise.all([
                    fetchTypes(),
                    fetchProducts(),
                    fetchAds()
                ]);
                product.setTypes(types);
                product.setProducts(products.rows);
                setAds(adsData);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };

        fetchData();
    }, [product]);

    // Load products when selectedTypeId changes
    useEffect(() => {
        const loadProducts = async () => {
            try {
                const data = await fetchProducts(selectedTypeId);
                product.setProducts(data.rows);
            } catch (error) {
                console.error('Ошибка при загрузке продуктов:', error);
            }
        };

        if (selectedTypeId) {
            loadProducts();
        } else {
            // Fetch all products if no type is selected
            fetchProducts().then(data => product.setProducts(data.rows));
        }
    }, [selectedTypeId, product]);

    // Handle ad save
    const handleAdSave = async () => {
        if (!adInfo.trim()) {
            alert('Информация объявления обязательна');
            return;
        }
        try {
            if (selectedAdId) {
                await updateAds(selectedAdId, { info: adInfo });
            } else {
                await createAds({ info: adInfo });
            }
            const adsData = await fetchAds();
            setAds(adsData);
            setAdInfo('');
            setAdVisible(false);
        } catch (error) {
            console.error('Ошибка при сохранении объявления:', error.response ? error.response.data : error.message);
        }
    };

    // Handle ad delete
    const handleAdDelete = async (id) => {
        try {
            await deleteAds(id);
            const updatedAds = await fetchAds();
            setAds(updatedAds);
        } catch (error) {
            console.error('Ошибка при удалении объявления:', error);
        }
    };

    // Handle ad edit
    const handleAdEdit = (ad) => {
        setSelectedAdId(ad.id);
        setAdInfo(ad.info);
        setAdVisible(true);
    };

    // Handle product delete
    const handleDelete = async () => {
        try {
            await deleteProduct(selectedProductId);
            setDeleteVisible(false);
            const data = await fetchProducts(selectedTypeId);
            product.setProducts(data.rows);
        } catch (error) {
            console.error('Ошибка при удалении продукта:', error);
        }
    };

    // Mark product as out of stock
    const markOutOfStock = async () => {
        if (selectedProductId) {
            try {
                const productToUpdate = product.products.find(p => p.id === selectedProductId);
                if (!productToUpdate) {
                    console.error('Продукт не найден:', selectedProductId);
                    return;
                }
                const outOfStockText = "(Нет в наличии)";
                let updatedProductName = productToUpdate.name;

                if (selectedStockStatus === 'out_of_stock' && !productToUpdate.name.includes(outOfStockText)) {
                    updatedProductName += ` ${outOfStockText}`;
                } else if (selectedStockStatus === 'in_stock' && productToUpdate.name.includes(outOfStockText)) {
                    updatedProductName = updatedProductName.replace(` ${outOfStockText}`, '');
                }

                const updatedProductData = { name: updatedProductName };
                await updateProduct(selectedProductId, updatedProductData);

                const updatedProducts = product.products.map(p => p.id === selectedProductId ? { ...p, name: updatedProductData.name } : p);
                product.setProducts(updatedProducts);
            } catch (error) {
                console.error('Ошибка при обновлении продукта:', error);
            }
        }
    };

    // Update product image
    const updateImage = async () => {
        if (selectedProductId && selectedImage) {
            try {
                await updateProductImage(selectedProductId, selectedImage);
                setSelectedImage(null);
                setDeleteVisible(false);
                const data = await fetchProducts(selectedTypeId);
                product.setProducts(data.rows);
            } catch (error) {
                console.error('Ошибка при обновлении изображения продукта:', error);
            }
        }
    };

    // Update product details
    const updateProductDetails = async () => {
        if (selectedProductId) {
            try {
                const updatedProductData = {};
                if (productName) updatedProductData.name = productName;
                if (productPrice) updatedProductData.price = productPrice;
                if (productDescription) {
                    updatedProductData.info = JSON.stringify([{ description: productDescription }]);
                }
                if (Object.keys(updatedProductData).length > 0) {
                    await updateProduct(selectedProductId, updatedProductData);
                    const updatedProducts = product.products.map(p => p.id === selectedProductId ? { ...p, ...updatedProductData } : p);
                    product.setProducts(updatedProducts);
                }
                setDeleteVisible(false);
            } catch (error) {
                console.error('Ошибка при обновлении продукта:', error);
            }
        }
    };

    // Handle type change
    const handleTypeChange = (e) => {
        setSelectedTypeId(e.target.value);
        setSelectedProductId(''); // Reset selected product when changing type
    };

    // Handle product change
    const handleProductChange = (e) => {
        const productId = e.target.value;
        setSelectedProductId(productId);
        const selectedProduct = product.products.find(p => p.id === productId);
        if (selectedProduct) {
            setProductName(selectedProduct.name);
            setProductPrice(selectedProduct.price);
            setProductDescription(selectedProduct.info ? JSON.parse(selectedProduct.info)[0].description : '');
        } else {
            // Reset product details if no product is selected
            setProductName('');
            setProductPrice('');
            setProductDescription('');
        }
    };

    // Handle stock status change
    const handleStockStatusChange = (e) => {
        setSelectedStockStatus(e.target.value);
    };

    // Handle image change
    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
    };

    return (
        <Container className='Container'>
            <Button
                className='custom-button'
                onClick={() => setProductVisible(true)}
                size='lg'
            >
                Добавить продукт
            </Button>
            <Button
                className='custom-button'
                onClick={() => setTypeVisible(true)}
                size='lg'
            >
                Добавить категорию
            </Button>
            <Button
                className='custom-button'
                onClick={() => setDeleteVisible(true)}
                size='lg'
            >
                Управление продуктами
            </Button>
            <Button
                className='custom-button'
                onClick={() => setAdVisible(true)}
                size='lg'
            >
                Управление объявлениями
            </Button>

            <CreateProduct show={productVisible} onHide={() => setProductVisible(false)} />
            <CreateType show={typeVisible} onHide={() => setTypeVisible(false)} />
            <CreateAds
                show={adVisible}
                onHide={() => {
                    setAdVisible(false);
                    setSelectedAdId(null);
                    setAdInfo('');
                }}
                ad={selectedAdId ? ads.find(ad => ad.id === selectedAdId) : null}
                onSave={handleAdSave}
                onInfoChange={(e) => setAdInfo(e.target.value)}
            />

            <Modal show={deleteVisible} onHide={() => setDeleteVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Управление продуктами</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select
                        aria-label="Выберите категорию"
                        onChange={handleTypeChange}
                        value={selectedTypeId}
                    >
                        <option value="">Все категории</option>
                        {product.types && product.types.length > 0 ? (
                            product.types.map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))
                        ) : (
                            <option value="">Нет доступных категорий</option>
                        )}
                    </Form.Select>
                    <Form.Select
                        className='mt-3'
                        aria-label="Выберите продукт"
                        onChange={handleProductChange}
                        disabled={!selectedTypeId}
                        value={selectedProductId}
                    >
                        <option value="">Выберите продукт</option>
                        {product.products && product.products.length > 0 ? (
                            product.products.map(p => (
                                <option key={p.id} value={p.id}>
                                    {p.name}
                                </option>
                            ))
                        ) : (
                            <option value="">Нет доступных продуктов</option>
                        )}
                    </Form.Select>
             
                    {selectedProductId && (
                        <>
                            <Form.Group className="mt-3">
                                <Form.Label>Название продукта</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Цена продукта</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={productPrice}
                                    onChange={(e) => setProductPrice(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Описание продукта</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={productDescription}
                                    onChange={(e) => setProductDescription(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formFile" className="mt-3">
                                <Form.Label>Выберите новое изображение</Form.Label>
                                <Form.Control type="file" onChange={handleImageChange} />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Статус наличия</Form.Label>
                                <Form.Select
                                    aria-label="Выберите статус наличия"
                                    value={selectedStockStatus}
                                    onChange={handleStockStatusChange}
                                >
                                    <option value="">Выберите статус</option>
                                    <option value="in_stock">В наличии</option>
                                    <option value="out_of_stock">Нет в наличии</option>
                                </Form.Select>
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteVisible(false)}>
                        Отмена
                    </Button>
                    <Button
                        variant="warning"
                        onClick={markOutOfStock}
                        disabled={!selectedProductId || !selectedStockStatus}
                    >
                        {selectedStockStatus === 'out_of_stock' ? 'Пометить как нет в наличии' : 'Убрать отметку "Нет в наличии"'}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={updateImage}
                        disabled={!selectedProductId || !selectedImage}
                    >
                        Обновить изображение
                    </Button>
                    <Button
                        variant="info"
                        onClick={updateProductDetails}
                        disabled={!selectedProductId}
                    >
                        Обновить детали
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDelete}
                        disabled={!selectedProductId}
                    >
                        Удалить
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={adVisible} onHide={() => setAdVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Управление объявлениями</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Информация объявления</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={adInfo}
                            onChange={(e) => setAdInfo(e.target.value)}
                        />
                    </Form.Group>
                    <div className="mt-3">
                        {ads.map((ad) => (
                            <div key={ad.id} className="d-flex justify-content-between align-items-center mb-2">
                                <div style={{ fontSize: '15px', flexWrap: 'wrap' }}>{ad.info}</div>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        variant="info"
                                        className="me-2"
                                        onClick={() => handleAdEdit(ad)}
                                    >
                                        Редактировать
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => handleAdDelete(ad.id)}
                                    >
                                        Удалить
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAdVisible(false)}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleAdSave}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
});

export default Admin;
