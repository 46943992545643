





// import axios from 'axios';

// // Создание экземпляров Axios
// const $host = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });

// const $authHost = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });

// // Интерсептор для авторизации
// const authInterceptor = config => {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.authorization = `Bearer ${token}`;
//     }
//     return config;
// };

// $authHost.interceptors.request.use(authInterceptor);

// export { $host, $authHost };





import axios from 'axios';
import Cookies from 'js-cookie';

// Создание экземпляров Axios
const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// Интерсептор для авторизации
const authInterceptor = config => {
    const token = Cookies.get('token');
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
