import React from 'react';
import { Col } from 'react-bootstrap';
import '../css/PublicOfferAgreement.css';
import { DELIVERYINFO_ROUTE,PDPPOLICY_ROUTE } from '../utils/consts';

import { useNavigate } from 'react-router-dom';

const PublicOfferAgreement = () => {
    const navigate = useNavigate();
    const handlenavigation = (route) => {
        navigate(route);
    };
    return (
        <Col style={{display:'flex', justifyContent:'center',flexWrap:'wrap'}}>
            <div className='headerText'>Договор публичной оферты</div>
            <section id='section1'>
                <h2>
                1.ОБЩИЕ ПОЛОЖЕНИЯ
                </h2>
                <div >
                    <p>
                    Настоящая политика обработки персональных данных (далее — Политика) разработана с целью защиты прав и свобод субъектов персональных данных, обеспечивая их права на неприкосновенность частной жизни, личную и семейную тайну при обработке персональных данных.
                        <br/>
                        <strong>1.1.</strong>
                        Настоящая публичная оферта (далее – Оферта) является официальным предложением ИП Авдеев Александр Викторович в адрес любого физического лица заключить с ИП Авдеев Александр Викторович договор розничной купли-продажи товара на Сайте дистанционным образом на условиях, определенных в настоящем Договоре и содержит все существенные условия Оферты.
                        <br/>
                        <strong>1.2.</strong>                  
                        Заказ Покупателем товара, размещенного на Сайте, означает, что Покупатель согласен со всеми условиями настоящей Оферты, Политики конфиденциальности и Пользовательского соглашения.
                        <br/>
                        <strong>1.3.</strong>
                        Сайт имеет право вносить изменения в Оферту без уведомления Покупателя.
                        <br/>
                        <strong>1.4.</strong>
                        Срок действия Оферты не ограничен, если иное не указано на Сайте.
                        <br/>
                        <strong>1.5.</strong>
                        Сайт предоставляет Покупателю полную и достоверную информацию о товаре/услугах, включая информацию об основных потребительских свойствах товара, месте изготовления, а также информацию о сроке годности товара.
                    
                        <h2>
                        2.Предмет Оферты
                        </h2>
                        <strong>2.1.</strong>
                        Сайт обязуется передать Покупателю товар, предназначенный для личного, семейного, домашнего или иного использования, не связанного с предпринимательской деятельностью, на основании размещенных Заказов, а Покупатель обязуется принять и оплатить Товар на условиях настоящей Оферты.
                        <br/>
                        <strong>2.2.</strong>
                        Наименование, цена, количество товара, а также прочие необходимые условия Оферты определяются на основании сведений, предоставленных Покупателем при оформлении заказа.
                        <br/>
                        <strong>2.3.</strong>
                        Право собственности на заказанные товары переходит к Покупателю с момента фактической передачи товара Покупателю и оплаты последним полной стоимости товара. Риск его случайной гибели или повреждения товара переходит к Покупателю с момента фактической передачи товара Покупателю.
                        <h2>
                        3.Стоимость товара
                        </h2>
                    
                        <strong>3.1.</strong>
                        Цены на товар определяются Продавцом в одностороннем бесспорном порядке и указываются на страницах интернет-магазина, расположенного по интернет-адресу: https://www.donkulinar.ru/
                        <br/>
                        <strong>3.2.</strong>
                        Цена товара указывается в рублях Российской Федерации.
                        <br/>
                        <strong>3.3.</strong>
                        Расчеты между Сайтом и Покупателем за товар производятся способами, указанными на Сайте в разделе – <a style={{cursor:'pointer',textDecoration: 'underline'}} onClick={() => handlenavigation(DELIVERYINFO_ROUTE)}>Доставка.</a>
                    
                        <h2>
                        4.Момент заключения Оферты 
                        </h2>
                    
                        <strong>4.1.</strong>
                        Акцептом настоящей Оферты (договора) является оформление Покупателем заказа на товар в соответствии с условиями настоящей Оферты. Оформление Покупателем заказа на товар производится путем совершения действий указанных в разделе «Как мы работаем».
                        <br/>
                        <strong>4.2.</strong>
                        <li>Регистрационные данные (в том числе персональные данные) указаны им добровольно</li>
                        <li>Регистрационные данные (в том числе персональные данные) передаются в электронной форме по каналам связи сети «Интернет»</li>
                        <li>Регистрационные данные (в том числе персональные данные) переданы Сайту для реализации целей, указанных в настоящей Оферте, <a style={{cursor:'pointer',textDecoration: 'underline'}} onClick={() => handlenavigation(PDPPOLICY_ROUTE)}>Политике конфиденциальности</a>, Пользовательском соглашении и могут быть переданы третьим лицам, для реализации целей, указанных в настоящей Оферте</li>
                        <li>Регистрационные данные (в том числе персональные данные) могут быть использованы Сайтом в целях продвижения товаров и услуг, путем осуществления прямых контактов с Покупателем с помощью каналов связи</li>
                        <li>В целях дополнительной защиты от мошеннических действий указанные Покупателем регистрационные данные (в том числе персональные данные) могут быть переданы банку, осуществляющему транзакции по оплате оформленных заказов</li>
                        <li>Данное Покупателем согласие на обработку его регистрационных данных (в том числе персональных данных) является бессрочным и может быть отозвано Покупателем или его законным представителем, подачей письменного заявления, переданного Сайту</li>

                        
                        <h2>
                            5.Возврат товара и денежных средств
                        </h2>
                      
                        <strong>5.1.</strong>
                        Возврат товара осуществляется в соответствии с Законом РФ «О защите прав потребителей».
                        <br/>
                        <strong>5.2.</strong>
                        Возврат денежных средств осуществляется посредством возврата стоимости оплаченного товара на банковскую карту, почтовым переводом или иным способом, не противоречащим требованиям действующего законодательства.
                      
                        <h2>
                            6.Доставка товара
                        </h2>
                       
                        <strong>6.1.</strong>
                        Доставка товара Покупателю осуществляется в сроки, согласованные Сторонами при подтверждении заказа сотрудником Сайта.
                        <br/>
                        <strong>6.2.</strong>
                        При курьерской доставке товара Покупатель ставит свою подпись в накладной с указанием тех позиций товара, которые Покупатель приобрел. Данная подпись служит подтверждением того, что Покупатель не имеет претензий к комплектации товара, к количеству и внешнему виду товара.
                        <br/>
                        <strong>6.3.</strong>
                        После получения товара претензии к количеству, комплектности и виду товара не принимаются.
                       
                        <h2>
                            7.Срок действия Оферты
                        </h2>
                      
                        <strong>7.1.</strong>
                        Настоящая Оферта вступает в силу с момента ее акцепта Покупателем, и действует до момента отзыва акцепта публичной Оферты.
                        
                        <h2>
                            8.Дополнительные условия
                        </h2>
                        <strong>8.1.</strong>
                        Сайт вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.
                        <br/>
                        <strong>8.2.</strong>
                        Сайт и предоставляемые сервисы могут временно частично или полностью недоступны по причине проведения профилактических или иных работ или по любым другим причинам технического характера. Техническая служба Сайта имеет право периодически проводить необходимые профилактические или иные работы с предварительным уведомлением Покупателей или без такового.
                        <br/>
                        <strong>8.3.</strong>
                        К отношениям между Покупателем и Сайтом применяются положения Российского законодательства.
                        <br/>
                        <strong>8.4.</strong>
                        В случае возникновения вопросов и претензий со стороны Покупателя он должен обратиться к Сайту по телефону или иным доступным способом. Все возникающее споры стороны будут стараться решить путем переговоров, при недостижении соглашения спор будет передан на рассмотрение в суд, в соответствии с действующим законодательством РФ.
                        <br/>
                        <h2>
                            9.Реквизиты Сайта
                        </h2>
                        <br/>
                        ИП Авдеев Александр Викторович<br/>
                        Адрес: 394091, Воронежская обл, г.Воронеж, ул.Заветная дом №15<br/>
                        Адрес: 394048, Воронежская обл, г.Воронеж, ул.Теплоэнергетиков, дом №2<br/>                       
                        ИНН: 366515489118<br/>
                        РС: 40802810403000000453<br/>
                        Банк: Филиал "СДМ-БАНК" (ПАО) в г. Воронеж<br/>
                       
                        БИК: 042007778<br/>
                        К/с 30101810500000000778<br/>
                        Email: donskiepekarni@gmail.com<br/>
                        +7 (473) 280 21 08
                    </p>
                </div>
            </section>
        </Col>
    );
};

export default PublicOfferAgreement;
