// // components/ScrollToTopArrow.js
// import React, { useState, useEffect } from 'react';
// import { FaArrowUp } from 'react-icons/fa';
// import '../css/ScrollToTopArrow.css';

// const ScrollToTopArrow = () => {
//     const [showArrow, setShowArrow] = useState(false);

//     useEffect(() => {
//         const handleScroll = () => {
//             if (window.scrollY > 200) {
//                 setShowArrow(true);
//             } else {
//                 setShowArrow(false);
//             }
//         };

//         window.addEventListener('scroll', handleScroll);
//         return () => window.removeEventListener('scroll', handleScroll);
//     }, []);

//     const scrollToTop = () => {
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//     };

//     return (
//         <div className={`scroll-to-top ${showArrow ? 'show' : ''}`} onClick={scrollToTop}>
//             <FaArrowUp />
//         </div>
//     );
// };

// export default ScrollToTopArrow;









import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import '../css/ScrollToTopArrow.css';

const ScrollToTopArrow = () => {
    const [showArrow, setShowArrow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowArrow(true);
            } else {
                setShowArrow(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={`scroll-to-top ${showArrow ? 'show' : ''}`} onClick={scrollToTop}>
            <FaArrowUp />
        </div>
    );
};

export default ScrollToTopArrow;
