import React from 'react';
import { Card, Button } from 'react-bootstrap';

const AdList = ({ ads }) => {
    return (
        <div className="ad-list">
            {ads.map(ad => (
                <div key={ad.id} >
                    <div>
                        <h3>{ad.info}</h3>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AdList;
