// import { observer } from 'mobx-react-lite';
// import React, { useContext } from 'react';
// import { Context } from '../index';
// import { Row } from 'react-bootstrap';
// import ProductItem from './ProductItem';
// import '../css/ProductList.css'
// const ProductList = observer(() => {
//     const {product} = useContext(Context)

//     return (
//         <Row  className='productlist'  sm={3} >
//             {product.products.map(product =>
//                 <ProductItem key={product.id} product={product}/>
//             )}
            
//         </Row>
//     );
// });


// export default ProductList;

import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Context } from '../index';
import { Row } from 'react-bootstrap';
import ProductItem from './ProductItem';
import '../css/ProductList.css';

const ProductList = observer(() => {
    const { product } = useContext(Context);

    // Группируем товары по первой букве названия
    const groupedProducts = product.products.reduce((groups, product) => {
        const firstLetter = product.name[0].toUpperCase();
        if (!groups[firstLetter]) {
            groups[firstLetter] = [];
        }
        groups[firstLetter].push(product);
        return groups;
    }, {});

    return (
        <Row className='productlist' sm={3}>
            {Object.keys(groupedProducts).sort().map(letter => (
                groupedProducts[letter].map(product => (
                    <ProductItem key={product.id} product={product} />
                ))
            ))}
        </Row>
    );
});

export default ProductList;
