import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import App from '../src/App';
import UserStore from './store/userStore';
import ProductStore from './store/ProductStore';
import './fonts.css'
export const Context = createContext(null);

const container = document.getElementById('root');
const root = createRoot(container);


root.render(
  <Context.Provider value={{ 
    user: new UserStore(),
    product: new ProductStore()
   }}>
    <App />
  </Context.Provider>
 
);
