



import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { CartProvider } from './components/CartContext';
import { SHOP_ROUTE, ADMIN_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE, BASKET_ROUTE, REDIRACTION_ROUTE, DELIVERYINFO_ROUTE,PDPPOLICY_ROUTE, POA_ROUTE} from './utils/consts';
import Shop from './pages/Shop';
import Admin from './pages/Admin';
import Auth from './pages/Auth';
import Basket from './pages/Basket';
import DeliveryInfo from './pages/DeliveryInfo';
import Rediraction from './pages/Redirection';
import PDPPolicy from './pages/PDPPolicy';
import PublicOfferAgreement from './pages/PublicOfferAgreement';
import { observer } from 'mobx-react-lite';
import { Context } from './index';
import { check } from './http/userAPI';
import { Spinner } from 'react-bootstrap';
import './fonts.css'
const App = observer(() => {
    const { user } = useContext(Context);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await check();
                if (userData) {
                    user.setUser(userData);
                    user.setIsAuth(true);
                } else {
                    user.setUser(null);
                    user.setIsAuth(false);
                }
            } catch (error) {
                console.error('Error during checkAuth:', error);
                user.setUser(null);
                user.setIsAuth(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    if (loading) {
        return <Spinner animation="grow" />;
    }

    return (
        <BrowserRouter>
            <CartProvider>
                <div id="root">
                    <NavBar />
                    <main>
                        <Routes>
                            <Route path={SHOP_ROUTE} element={<Shop />} />
                            <Route path={ADMIN_ROUTE} element={<Admin />} />
                            <Route path={REGISTRATION_ROUTE} element={<Auth />} />
                            <Route path={LOGIN_ROUTE} element={<Auth />} />
                            <Route path={BASKET_ROUTE} element={<Basket />} />
                            <Route path={REDIRACTION_ROUTE} element={<Rediraction />} />
                            <Route path={DELIVERYINFO_ROUTE} element={<DeliveryInfo />} />
                            <Route path={PDPPOLICY_ROUTE} element={<PDPPolicy />} />
                            <Route path={POA_ROUTE} element={<PublicOfferAgreement />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </CartProvider>
        </BrowserRouter>
    );
});

export default App;






