






    import { makeAutoObservable } from 'mobx';

    export default class ProductStore {
        constructor() {
            this._types = [];
            this._carousel = [];
            this._ads = [];
            this._products = [];
            this._selectedType = {};
            this._selectedSubtype = {}; // Добавляем выбранный подтип
            this._page = 1;
            this._totalCount = 0;
            this._limit = 5; // Значение по умолчанию
            makeAutoObservable(this);
        }
        
        setAds(ads){
            this._ads = ads
        }
        setCarousel(carousel){
            this._carousel = carousel
        }
        setTypes(types) {
            this._types = types;
        }

        

        setProducts(products) {
            this._products = products;
        }

        setSelectedType(type) {
            this._selectedType = type;
            this.setPage(1);
        }



        setPage(page) {
            this._page = page;
        }

        setTotalCount(count) {
            this._totalCount = count;
        }

        setLimit(limit) {
            this._limit = limit;
        }

        // Геттеры
        get ads(){
            return this._ads;
        }
        get carousel(){
            return this._carousel;
        }
        get types() {
            return this._types;
        }

    

        get products() {
            return this._products;
        }

        get selectedType() {
            return this._selectedType;
        }

        get selectedSubtype() { // Геттер для выбранного подтипа
            return this._selectedSubtype;
        }

        get page() {
            return this._page;
        }

        get totalCount() {
            return this._totalCount;
        }

        get limit() {
            return this._limit;
        }
    }
