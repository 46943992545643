import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import '../css/ExampleCarouselImage.css';

const ExampleCarouselImage = ({ src, alt }) => {
    return (
        <div className="example-carousel-image-container">
            <Image src={src} alt={alt} fluid className="example-carousel-image" />
        </div>
    );
};

ExampleCarouselImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};

export default ExampleCarouselImage;
