import { jwtDecode } from "jwt-decode";
import { makeAutoObservable } from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = !!localStorage.getItem('token')
        this._user = this._isAuth ? this.loadUserFromToken() : {};
        this._user = {};
        makeAutoObservable(this);
    }

    setIsAuth(bool) {
        this._isAuth = bool;
    }
    loadUserFromToken(){
        try{
            const token = localStorage.getItem('token');
            return token ? jwtDecode(token) : {};
        }catch(error){
            console.log("Ошибка при декодировани токена :",error);
            return {};
        }
    }
    setUser(user) {
        if (user) {
            this._id = user.id;
            this._number = user.number;
            this._role = user.role;
        } else {
            this._id = null;
            this._number = null;
            this._role = null;
        }
    }

    get IsAuth() {
        return this._isAuth;
    }

    get id() {
        return this._id;
    }

    get number() {
        return this._number;
    }

    get role() {
        return this._role;
    }
}

