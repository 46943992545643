// api.js
import { $authHost, $host } from "./index";

// Функция для создания нового типа продукта
export const createType = async (type) => {
    try {
        const { data } = await $authHost.post('api/type', type);
        return data;
    } catch (error) {
        console.error('Ошибка при создании типа:', error);
        throw error;
    }
};

// Функция для получения всех типов продуктов
export const fetchTypes = async () => {
    try {
        const { data } = await $host.get('api/type');
       
        return data;
    } catch (error) {
        console.error('Ошибка при получении типов:', error);
        throw error;
    }
};

// Функция для создания нового продукта
export const createProduct = async (product) => {
    try {
        
        const { data } = await $authHost.post('api/product', product);
        return data;
    } catch (error) {
        console.error('Ошибка при создании продукта:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Функция для получения списка продуктов с фильтрацией и пагинацией
export const fetchProducts = async (typeId, page = 1, limit = 100, maxPrice, sortOrder, query) => {
    try {
        const { data } = await $host.get('api/product', {
            params: { typeId, page, limit, maxPrice, sortOrder, query }
        });

        

        return data;
    } catch (error) {
        console.error('Ошибка при получении продуктов:', error);
        throw error;
    }
};

// Функция для удаления продукта
export const deleteProduct = async (id) => {
    try {
        const { data } = await $authHost.delete(`api/product/${id}`);
        return data;
    } catch (error) {
        console.error('Ошибка при удалении продукта:', error);
        throw error;
    }
};

// Функция для получения одного продукта по ID
export const fetchOneProduct = async (id) => {
    try {
        const { data } = await $host.get(`api/product/${id}`);
        
        return data;
    } catch (error) {
        console.error('Ошибка при получении продукта:', error);
        throw error;
    }
};

// Функция для обновления продукта
export const updateProduct = async (id, updateData) => {
    try {
        const { data } = await $authHost.put(`api/product/${id}`, updateData);
        return data;
    } catch (error) {
        console.error('Ошибка при обновлении продукта:', error);
        throw error;
    }
};

// Функция для обновления изображения продукта
export const updateProductImage = async (id, image) => {
    try {
        const formData = new FormData();
        formData.append('img', image);
        const { data } = await $authHost.put(`api/product/image/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {
        console.error('Ошибка при обновлении изображения продукта:', error);
        throw error;
    }
};

// Функция для получения всех объявлений
export const fetchAds = async () => {
    try {
        const { data } = await $authHost.get('api/ads');
        return data;
    } catch (error) {
        console.error('Ошибка при получении объявлений:', error);
        throw error;
    }
};

// Функция для создания нового объявления
export const createAds = async (adData) => {
    try {
        const { data } = await $authHost.post('api/ads', adData);
        return data;
    } catch (error) {
        console.error('Ошибка при создании объявления:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Функция для обновления существующего объявления
export const updateAds = async (id, adData) => {
    try {
        const { data } = await $authHost.put(`api/ads/${id}`, adData);
        return data;
    } catch (error) {
        console.error('Ошибка при обновлении объявления:', error.response ? error.response.data : error.message);
        throw error;
    }
};

// Функция для удаления объявления
export const deleteAds = async (id) => {
    try {
        const { data } = await $authHost.delete(`api/ads/${id}`);
        return data;
    } catch (error) {
        console.error('Ошибка при удалении объявления:', error);
        throw error;
    }
};
