import React, { useContext, useEffect, useState, useReducer } from 'react';
import { Button, Modal, Form, Dropdown, Row, Col } from 'react-bootstrap';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { createProduct, fetchTypes } from '../../http/productAPI';

// Reducer для управления состоянием info
const infoReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_INFO':
            return [...state, { description: '', number: Date.now() }];
        case 'REMOVE_INFO':
            return state.filter(i => i.number !== action.number);
        case 'CHANGE_INFO':
            return state.map(i => i.number === action.number ? { ...i, [action.key]: action.value } : i);
        default:
            return state;
    }
};

const useLoadData = (product) => {
    useEffect(() => {
        const loadData = async () => {
            try {
                const types = await fetchTypes();
                product.setTypes(types);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        loadData();
    }, [product]);
};

const CreateProduct = observer(({ show, onHide }) => {
    const { product } = useContext(Context);

    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [file, setFile] = useState(null);
    const [info, dispatch] = useReducer(infoReducer, []);

    useLoadData(product);

    const addInfo = () => dispatch({ type: 'ADD_INFO' });
    const removeInfo = (number) => dispatch({ type: 'REMOVE_INFO', number });
    const changeInfo = (key, value, number) => dispatch({ type: 'CHANGE_INFO', key, value, number });

    const selectFile = e => {
        setFile(e.target.files[0]);
    };

    const addProduct = async () => {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('price', `${price}`);
            formData.append('img', file);
            formData.append('typeId', product.selectedType?.id);
            formData.append('info', JSON.stringify(info));

            await createProduct(formData);
            onHide();
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    const selectedTypeName = product.selectedType?.name || "Выберите категорию";

    const typesMenuItems = product.types.map(type => (
        <Dropdown.Item
            onClick={() => {
                product.setSelectedType(type);
            }}
            key={type.id}
        >
            {type.name}
        </Dropdown.Item>
    ));

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить название продукта
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Dropdown>
                        <Dropdown.Toggle>{selectedTypeName}</Dropdown.Toggle>
                        <Dropdown.Menu>{typesMenuItems}</Dropdown.Menu>
                    </Dropdown>
                    <Form.Control
                        className='mt-3'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Введите название продукта'
                    />
                    <Form.Control
                        className='mt-3'
                        value={price}
                        onChange={e => setPrice(Number(e.target.value))}
                        placeholder='Введите стоимость продукта'
                        type='number'
                    />
                    <Form.Control
                        className='mt-3'
                        placeholder='Введите картинку продукта'
                        type='file'
                        onChange={selectFile}
                    />
                    <Button
                        variant='outline-dark'
                        className='mt-3'
                        onClick={addInfo}
                    >
                        Введите описание продукта
                    </Button>
                    {info.map(i => (
                        <Row key={i.number} className='mt-3'>
                            <Col>
                                <Form.Control
                                    value={i.description}
                                    onChange={(e) => changeInfo('description', e.target.value, i.number)}
                                    placeholder="Введите описание"
                                />
                            </Col>
                            <Col>
                                <Button
                                    variant='outline-danger'
                                    onClick={() => removeInfo(i.number)}
                                >
                                    Удалить
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Закрыть</Button>
                <Button variant='outline-success' onClick={addProduct}>Добавить</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CreateProduct;
