import React from 'react';
import { Col } from 'react-bootstrap';
import '../css/PDPPolicy.css';

const PDPPolicy = () => {
    return (
        <Col style={{display:'flex', justifyContent:'center',flexWrap:'wrap'}}>
            <div className='headerText'>Политика обработки персональных данных</div>
            <section id='section1'>
                <h2>
                1.ОБЩИЕ ПОЛОЖЕНИЯ
                </h2>
                <div style={{marginBottom:'50px'}}>
                    <p>
                    Настоящая политика обработки персональных данных (далее — Политика) разработана с целью защиты прав и свобод субъектов персональных данных, обеспечивая их права на неприкосновенность частной жизни, личную и семейную тайну при обработке персональных данных.
                        <br/>
                        <strong>1.2. Основные понятия. </strong>
                        
                        Персональные данные — любая информация, которая может быть связана с прямо или косвенно идентифицируемым физическим лицом (субъектом персональных данных).
                        Обработка персональных данных — любые действия или совокупность действий, выполняемых с использованием средств автоматизации или без них, включая:
                        сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.
                        Автоматизированная обработка персональных данных — обработка персональных данных с использованием вычислительной техники.
                        Распространение персональных данных — действия, направленные на раскрытие персональных данных неограниченному кругу лиц.
                        Предоставление персональных данных — действия, направленные на раскрытие персональных данных конкретному лицу или определенной группе лиц.
                        Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, когда обработка необходима для уточнения персональных данных).
                        Уничтожение персональных данных — действия, после которых невозможно восстановить содержание персональных данных в информационной системе или уничтожаются носители персональных данных.
                        Обезличивание персональных данных — действия, после которых без использования дополнительной информации невозможно определить принадлежность данных конкретному субъекту.
                        Оператор персональных данных (Оператор) — государственный орган, муниципальный орган, юридическое или физическое лицо, организующее и (или) осуществляющее обработку персональных данных и определяющее цели их обработки, состав данных, подлежащих обработке, и действия (операции), совершаемые с ними.
                        <br/>
                        <strong>1.3. Обязанности оператора. </strong>
                        
                        Оператор, получивший доступ к персональным данным, обязан соблюдать их конфиденциальность, не раскрывая и не распространять данные без согласия субъекта, если иное не предусмотрено федеральным законом.
                        <br/>
                        <strong>1.4. Права субъекта персональных данных. </strong>
                        
                        Субъект персональных данных имеет право получать информацию о своей обработке данных, включая:
                        Подтверждение факта обработки данных Оператором.
                        Правовые основания и цели обработки данных.
                        Цели и способы обработки данных, используемые Оператором.
                        Наименование и место нахождения Оператора, информацию о лицах, имеющих доступ к данным.
                        Обрабатываемые персональные данные, источник их получения (если иной порядок не предусмотрен законом).
                        Сроки обработки и хранения данных.
                        Порядок реализации прав субъекта, предусмотренных Федеральным законом.
                        Информацию о трансграничной передаче данных.
                        Данные лица, осуществляющего обработку персональных данных по поручению Оператора.
                        Иные сведения, предусмотренные законодательством.
                        <br/>
                        <strong>1.5. Право на корректировку данных. </strong>
                        
                        Субъект персональных данных вправе требовать уточнения, блокирования или уничтожения своих данных в случае их неполноты, неточности, незаконного получения или если данные не являются необходимыми для заявленных целей обработки.
                        <br/>
                        <strong>1.6. Защита прав субъекта. </strong>
                        Субъект персональных данных имеет право на судебную защиту своих прав и законных интересов, включая возмещение убытков и компенсацию морального вреда.
                        <br/>
                        <strong>1.7. Права оператора. </strong>
                        
                        Оператор имеет право:                      
                        Защищать свои интересы в суде.
                        Предоставлять данные третьим лицам, если это предусмотрено законодательством (налоговым, правоохранительным органам и т.д.).
                        Отказывать в предоставлении данных в случаях, предусмотренных законом.
                        Использовать данные без согласия субъекта в случаях, предусмотренных законом.
                        <br/>
                        <strong>1.8. Обязанность оператора при сборе данных. </strong>
                        
                        При сборе данных Оператор обязан предоставить субъекту информацию, предусмотренную статьей 14 Федерального закона "О персональных данных".
                        <br/>
                        <strong>1.9. Обработка данных через интернет. </strong>
                        
                        При сборе персональных данных через интернет Оператор обязан обеспечить их запись, систематизацию, накопление, хранение, уточнение и извлечение с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в Федеральном законе.
                    </p>
                    <h2>
                    2.ОСНОВНЫЕ ПРАВА ОПЕРАТОРА
                    </h2>
                    <strong>2.1. Обязанность оператора при сборе данных. </strong>
                        
                    Оператор оставляет за собой право проверить полноту и точность предоставленных персональных данных. В случае выявления ошибочных или неполных персональных данных, Оператор имеет право прекратить все отношения с субъектом персональных данных.
                    
                    <h2>
                    3.ОСНОВНЫЕ ОБЯЗАННОСТИ ОПЕРАТОРА
                    </h2>
                    <strong>3.1.</strong>                        
                    Оператор не собирает персональные данные, не обрабатывает и не передает персональные данные субъектов персональных данных третьим лицам, без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
                    
                    <h2>
                    4.ОСНОВНЫЕ ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <strong>4.1.  </strong>     
                    Субъект персональных данных имеет право: 
                    <br/>                  
                    1) получить сведения, касающиеся обработки его персональных данных Оператором;<br/>

                    2) потребовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;<br/>

                    3) просить прекратить обработку его персональных данных в предусмотренных законом случаях.<br/>
                    <h2>
                    5.ЦЕЛИ СБОРА ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <strong>5.1.  </strong>     
                    Обработка персональных данных Пользователей Сайта осуществляется в целях предоставления Пользователю возможности взаимодействовать с Сайтом.<br/>
                    <strong>5.2.  </strong>     
                    Предоставление услуг по доставке продуктов питания, а также сопутствующих товаров.<br/>
                    <strong>5.3.  </strong>   
                    Сбор статистики по типам обращения посетителей Сайта.<br/>  
                    <h2>
                    6.ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <strong>6.1.</strong>   
                    Согласие субъекта на обработку персональных данных.<br/>
                    <strong>6.2.</strong>   
                    Федеральные законы и принятые на их основе нормативные правовые акты, регулирующие отношения, связанные с деятельностью оператора.<br/>
                    <strong>6.3.</strong>   
                    Договоры, заключаемые между оператором и субъектом персональных данных.
                    <h2>
                    7.ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <br/>
                    <strong>7.1.</strong>   
                    Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.
                    <br/>
                    <strong>7.2.</strong>   
                    Обработка персональных данных допускается в следующих случаях:<br/>
                    – обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;<br/>

                    – обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных.<br/>
                    <br/>
                    <strong>7.3.</strong>   
                    К категориям субъектов персональных данных относятся:
                    <br/>
                    <strong>7.3.1.</strong>   
                    Клиенты и контрагенты оператора (физические лица).<br/>
                    В данной категории субъектов оператором обрабатываются персональные данные, полученные оператором в связи с заключением договора, стороной которого является субъект персональных данных, и используемые оператором исключительно для исполнения указанного договора и заключения договоров с субъектом персональных данных:<br/>
                    – фамилия, имя;
                    <br/>
                    – адрес для доставки товаров или оказания услуг (район, город, поселок, деревня, иной населенный пункт, улица, дом, корпус, квартира, подъезд, этаж);
                    <br/>
                    – номера телефонов (домашний, мобильный, рабочий);    
                    <br/>
                    <strong>7.3.2.</strong>   
                    Представители/работники клиентов и контрагентов оператора (юридических лиц).<br/>
                    В данной категории субъектов оператором обрабатываются персональные данные, полученные оператором в связи с заключением договора, стороной которого является клиент/контрагент (юридическое лицо), и используемые оператором исключительно для исполнения указанного договора:<br/>
                    – фамилия, имя, отчество;
                    <br/>
                    – номера телефонов (домашний, мобильный, рабочий);
                    <br/>
                    – адрес электронной почты;
                    <br/>
                    – наименование организации;
                    <br/>
                    – ИНН;
                    <br/>
                    – адрес для доставки товаров или оказания услуг (район, город, поселок, деревня, иной населенный пункт, улица, дом, корпус, квартира/офис, подъезд, этаж);
                    <br/>
                    – занимаемая должность;
                    <br/>
                    – номер расчетного счета.
                    <br/>
                    <strong>7.4.</strong>   
                    Эти данные собираются в целях оказания услуг или продажи товаров, связи с пользователем или иной активности пользователя на сайте, а также, чтобы отправлять пользователям информацию, которую они согласились получать.
                    Мы не проверяем достоверность оставляемых данных, однако не гарантируем качественного исполнения заказов или обратной связи с нами при некорректных данных.
                    Данные собираются имеющимися на сайте формами для заполнения (например, регистрации, оформления заказа, подписки, оставления отзыва, обратной связи и иными).
                    Формы, установленные на сайте, могут передавать данные как напрямую на сайт, так и на сайты сторонних организаций (скрипты сервисов сторонних организаций).
                    Также данные могут собираться через технологию cookies (куки) как непосредственно сайтом, так и скриптами (программами) сервисов сторонних организаций. Эти данные собираются автоматически, отправку этих данных можно запретить, отключив cookies (куки) в браузере, в котором открывается сайт.
                    <h2>
                    8.ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <br/><strong>8.1.</strong>   
                    Оператор осуществляет обработку персональных данных с использованием средств автоматизации, в том числе с использованием внутренней корпоративной сети и сети Интернет.
                    <br/><strong>8.2.</strong>   
                    Обработка персональных данных осуществляется с соблюдением принципов и правил, предусмотренных Федеральным законом “О персональных данных”.
                    <br/><strong>8.3.</strong>   
                    Обработка персональных данных оператором ограничивается достижением конкретных, заранее определенных и законных целей. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки.
                    <br/><strong>8.4.</strong>  
                    На сайте могут быть установлены формы, собирающие персональную информацию других организаций, в этом случае сбор, хранение и защита персональной информации пользователя осуществляется сторонними организациями в соответствии с их политикой конфиденциальности.
                    Сбор, хранение и защита полученной от сторонней организации информации осуществляется в соответствии с настоящей политикой конфиденциальности:
                    ООО «Яндекс» через Яндекс.Метрику.
                    Условия передачи персональных данных: Политика конфиденциальности ООО «Яндекс»: https://yandex.ru/legal/confidential/index.html
                    Местонахождение третьего лица: 119021 г. Москва, ул. Льва Толстого, 16.
                    Осуществляется передача персональных данных без трансграничной передачи данных.
                    Цели передачи персональных данных: сбор статистики по посетителям Сайта.
                    Объем передаваемых данных: более 100000 субъектов.
                    Категории передаваемых данных: IP-адрес, cookies, версия браузера, версия операционной системы.
                    Перечень действий, разрешенных третьему лицу: сбор, запись, систематизация, накопление, хранение, использование, передача (предоставление, доступ), обезличивание, удаление.
                    Способы обработки персональных данных третьим лицом: автоматизированная обработка персональных данных с передачей по сети Интернет.
                    Требования к защите персональных данных третьим лицом установлены политикой конфиденциальности ООО «Яндекс». 
                    <br/><strong>8.5.</strong>
                    Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
                    <br/><strong>8.6.</strong>   
                    Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия или отзыв согласия субъекта персональных данных на обработку его персональных данных, а также выявление неправомерной обработки персональных данных.
                    <br/><strong>8.7.</strong>   
                    Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
                    <br/> <strong>8.8.</strong>   
                    Оператор при обработке персональных данных принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.
                    <h2>
                    9.АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <br/> <strong>9.1.</strong>   
                    Оператор при обработке персональных данных принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.
                    <br/> <strong>9.2.</strong>   
                    Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных.
                    <br/>  <strong>9.3.</strong>   
                    В случае подтверждения факта неточности персональных данных оператор на основании сведений, представленных субъектом персональных данных или его представителем либо уполномоченным органом по защите прав субъектов персональных данных, или иных необходимых документов обязан уточнить персональные данные либо обеспечить их уточнение.
                    <br/>  <strong>9.4.</strong>   
                    Оператор обязан прекратить обработку персональных данных или обеспечить прекращение обработки персональных данных лицом, действующим по поручению оператора:
                    <br/>
                    – в случае выявления неправомерной обработки персональных данных, осуществляемой оператором или лицом, действующим по поручению оператора, в срок, не превышающий трех рабочих дней с даты этого выявления;
                    <br/>
                    – в случае отзыва субъектом персональных данных согласия на обработку его персональных данных;
                    <br/>
                    – в случае достижения цели обработки персональных данных и уничтожить персональные данные или обеспечить их уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению оператора) в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных. В случае отсутствия возможности уничтожения персональных данных в течение указанного срока оператор осуществляет блокирование таких персональных данных или обеспечивает их блокирование (если обработка персональных данных осуществляется другим лицом, действующим по поручению оператора) и обеспечивает уничтожение персональных данных в срок не более чем шесть месяцев, если иной срок не установлен федеральными законами.
                    <h2>
                    10.ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h2>
                    <strong>10.1.</strong>   
                    Компания предоставляет субъекту персональных данных ответ на запрос о получении информации, касающейся обработки его персональных данных, на основании соответствующего письменного заявления субъекта персональных данных.
                    <br/><strong>10.2.</strong>   
                    Адрес для обращений субъектов персональных данных:<br/>
                    <br/> +7 (473) 280 21 08<br/> e-mail: donskiepekarni@gmail.com
                </div >
            </section>
        </Col>
    );
};

export default PDPPolicy;
