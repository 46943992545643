import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

const TOKEN_NAME = 'token';
const REFRESH_TOKEN_NAME = 'refreshToken';

export const registration = async (number, password) => {
    try {
        const { data } = await $host.post('api/user/registration', { number, password });
        Cookies.set(TOKEN_NAME, data.token, { expires: 1 }); // Срок действия 1 день
        Cookies.set(REFRESH_TOKEN_NAME, data.refreshToken, { expires: 30 }); // Срок действия 30 дней
        return jwtDecode(data.token);
    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
};

export const login = async (number, password) => {
    try {
        const { data } = await $host.post('api/user/login', { number, password });
        Cookies.set(TOKEN_NAME, data.token, { expires: 1 }); // Срок действия 1 день
        Cookies.set(REFRESH_TOKEN_NAME, data.refreshToken, { expires: 30 }); // Срок действия 30 дней
        return jwtDecode(data.token);
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};

export const check = async () => {
    try {
        const token = Cookies.get(TOKEN_NAME);
        if (!token) {
            console.warn('No token found in cookies');
            return null;
        }
        
        const { data } = await $authHost.get('api/user/auth');
        Cookies.set(TOKEN_NAME, data.token, { expires: 1 }); // Обновляем токен
        return jwtDecode(data.token);
    } catch (error) {
        console.error('Error during token check:', error);
        if (error.response && error.response.status === 401) {
            await refresh();
        } else {
            throw error;
        }
    }
};

const refresh = async () => {
    const refreshToken = Cookies.get(REFRESH_TOKEN_NAME);
    if (!refreshToken) {
        console.warn('No refresh token found in cookies');
        Cookies.remove(TOKEN_NAME);
        Cookies.remove(REFRESH_TOKEN_NAME);
        window.location.href = '/login';
        return;
    }

    try {
        const { data } = await $host.post('api/user/refresh', { refreshToken });
        Cookies.set(TOKEN_NAME, data.token, { expires: 1 });
        Cookies.set(REFRESH_TOKEN_NAME, data.refreshToken, { expires: 30 });
        return jwtDecode(data.token);
    } catch (error) {
        console.error('Error during token refresh:', error);
        Cookies.remove(TOKEN_NAME);
        Cookies.remove(REFRESH_TOKEN_NAME);
        window.location.href = '/login';
        throw error;
    }
};