
import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { fetchProducts } from '../http/productAPI';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    }, []);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = useCallback((product) => {
        setCartItems((prevItems) => {
            const itemIndex = prevItems.findIndex(item => item.id === product.id);
            if (itemIndex > -1) {
                const updatedItems = [...prevItems];
                const newQuantity = product.typeId === 9 
                    ? Math.max(2, updatedItems[itemIndex].quantity + 1) 
                    : updatedItems[itemIndex].quantity + 1;
    
                updatedItems[itemIndex] = {
                    ...updatedItems[itemIndex],
                    quantity: newQuantity,
                    totalPrice: newQuantity * product.price  // рассчитываем общую цену
                };
                return updatedItems;
            } else {
                const initialQuantity = product.typeId === 9 ? 2 : 1;
                return [...prevItems, { 
                    ...product, 
                    quantity: initialQuantity, 
                    totalPrice: initialQuantity * product.price  // рассчитываем общую цену при добавлении
                }];
            }
        });
    }, []);

    const removeFromCart = useCallback((productId) => {
        setCartItems((prevItems) => prevItems.filter(item => item.id !== productId));
    }, []);

    const updateQuantity = useCallback((productId, quantity) => {
        setCartItems((prevItems) => 
            prevItems.map(item => {
                if (item.id === productId) {
                    // Для товаров с typeId === 9, если количество меньше 2, устанавливаем его в 2
                    if (item.typeId === 9) {
                        return { ...item, quantity: Math.max(2, quantity) };
                    }
                    // Для всех других товаров, обновляем количество как обычно
                    return { ...item, quantity };
                }
                return item;
            })
        );
    }, [removeFromCart]);

    const loadProductsByType = useCallback(async (typeId) => {
        setLoading(true);
        try {
            const data = await fetchProducts(typeId);
            setProducts(data);
            setError(null);
        } catch (err) {
            setError(err.message);
            setProducts([]);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <CartContext.Provider value={{ 
            cartItems, 
            addToCart, 
            removeFromCart, 
            updateQuantity, 
            setCartItems, 
            products, 
            loadProductsByType, 
            loading, 
            error 
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
