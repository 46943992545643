import React, { useContext, useState } from 'react';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import { LOGIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE,PDPPOLICY_ROUTE } from '../utils/consts';
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { login, registration } from '../http/userAPI';
import { observer } from 'mobx-react-lite';
import { Context } from '..';

const Auth = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const isLogin = location.pathname === LOGIN_ROUTE;
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [numberError, setNumberError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [agree, setAgree] = useState(false); // Состояние для радиокнопки
    const [agreeError, setAgreeError] = useState(''); // Состояние для ошибки радиокнопки

    const validateNumber = (number) => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        return phoneRegex.test(number);
    };

    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const click = async () => {
        let isValid = true;

        if (!validateNumber(number)) {
            setNumberError('Некорректный номер телефона.');
            isValid = false;
        } else {
            setNumberError('');
        }

        if (!validatePassword(password)) {
            setPasswordError('Пароль должен быть не менее 6 символов.');
            isValid = false;
        } else {
            setPasswordError('');
        }

        if (!agree) {
            setAgreeError('Вы должны согласиться с политикой обработки персональных данных.');
            isValid = false;
        } else {
            setAgreeError('');
        }

        if (!isValid) {
            return;
        }

        try {
            let data;
            if (isLogin) {
                data = await login(number, password);
            } else {
                data = await registration(number, password);
            }
            user.setUser(data);
            user.setIsAuth(true);
            navigate(SHOP_ROUTE);
        } catch (e) {
            console.error('Authentication error:', e);
            alert(e.response?.data?.message || 'Ошибка при аутентификации');
        }
    };

    return (
        <Container 
            className='d-flex justify-content-center align-items-center'
            style={{ height: window.innerHeight - 54 }}
        >
            <Card className='p-5'>
                <h2 className='m-auto'>{isLogin ? 'Авторизация' : 'Регистрация'}</h2>
                <Form className='d-flex flex-column'>
                    <Form.Control
                        className='mt-3'
                        placeholder='Телефон'
                        value={number}
                        onChange={e => setNumber(e.target.value)}
                        isInvalid={!!numberError}
			onPaste={(e) => e.preventDefault()}
                        onKeyDown={(e) => {
                            if (e.ctrlKey && e.key === 'v') {
                            e.preventDefault();
                            }
                        }}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {numberError}
                    </Form.Control.Feedback>

                    <Form.Control
                        className='mt-3'
                        placeholder='Пароль'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type='password'
                        isInvalid={!!passwordError}
			onPaste={(e) => e.preventDefault()}
                        onKeyDown={(e) => {
                            if (e.ctrlKey && e.key === 'v') {
                            e.preventDefault();
                            }
                        }}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {passwordError}
                    </Form.Control.Feedback>
                    
                    <Form.Check
                        type="checkbox"
                        className="mt-3"
                        id="agreeCheckbox"
                        label={
                            <NavLink 
                                htmlFor="agreeCheckbox" 
                                to={PDPPOLICY_ROUTE}
                                style={{ cursor: "pointer", textDecoration: "underline", color: "rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))" }}
                            >
                                Согласен с политикой обработки персональных данных
                            </NavLink>
                        }
                        checked={agree}
                        onChange={e => setAgree(e.target.checked)}
                        isInvalid={!!agreeError}
                    />
                    <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
                        {agreeError}
                    </Form.Control.Feedback>

                    <Row className="d-flex justify-content-between mt-3 pl-3 pr-3">
                        
                        {isLogin ?
                            <div>
                                Нет аккаунта? <NavLink to={REGISTRATION_ROUTE}>Регистрация</NavLink>
                            </div>
                            :
                            <div>
                                Есть аккаунт? <NavLink to={LOGIN_ROUTE}>Войти</NavLink>
                            </div>
                        }
                        <div>
                            <Button
                                variant={'outline-primary'}
                                className='maps'
                                onClick={click}
                            >
                                {isLogin ? "Войти" : 'Регистрация'}
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Card>
        </Container>
    );
});

export default Auth;
