









import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Context } from '../index';
import { ListGroup, Button, Collapse } from 'react-bootstrap';
import '../css/TypeBar.css'; // Убедитесь, что вы подключаете CSS файл

const TypeBar = observer(() => {
    const { product } = useContext(Context);




    return (
        <div className="type-bar-container">
            
           
            <ListGroup horizontal className="type-bar-list">
                {product.types.map(type => (
                    <ListGroup.Item 
                        style={{ cursor: 'pointer' }}
                        onClick={() => product.setSelectedType(type)}
                        active={type.id === product.selectedType?.id}
                        key={type.id} 
                        className="pl-3"
                    >
                        {type.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            
        </div>
    );
});

export default TypeBar;
