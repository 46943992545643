import React from 'react';
import { Col } from 'react-bootstrap';
import '../css/DeliveryInfo.css';

const DeliveryInfo = () => {
    return (
        <Col >
            <div className='delivery'>Доставка</div>
            <div >
                <li className='info-paragraph'>
                При заказе от 600 руб. Доставка оплачивается дополнительно 300 руб.
                </li>
                <li className='info-paragraph'>
                При заказе от 1000 руб доставка бесплатна.
                </li>
                <li className='info-paragraph'>
                Заказать продукцию можно с 10:00 до 21:30.
                </li>
                <li className='info-paragraph'>
                Оплата возможна наличными и безналичными курьеру .
                </li>
                <li className='info-paragraph'>
                Доставка товара курьером до покупателя согласовывается по телефону.
                </li>
            </div>
        </Col>
    );
};

export default DeliveryInfo;
