




// import React, { useContext, useState } from 'react';
// import { Card, Image, Col } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { CiCircleCheck } from "react-icons/ci";
// import basket from '../photoNvabar/Navigation icon/basket black.png';
// import '../css/ProductItem.css';
// import { CartContext } from '../components/CartContext';
// import ProductPage from '../pages/ProductPage';

// const ProductItem = ({ product }) => {
//     const [show, setShow] = useState(false);
//     const [addedToCart, setAddedToCart] = useState(false);
//     const navigate = useNavigate();
//     const { addToCart } = useContext(CartContext);

//     const handleShow = () => setShow(true);
//     const handleClose = () => setShow(false);
    
//     const handleAddToCart = (e) => {
//         e.stopPropagation();
//         addToCart(product);
//         setAddedToCart(true);
//         setTimeout(() => setAddedToCart(false), 2000);
//     };
    
//     return (
//         <>
//             <Col md={3} className='d-flex justify-content-around'>
//                 <Card style={{ width: 250, cursor: 'pointer' }} className="mb-3" onClick={handleShow}>
//                     <div className="d-flex justify-content-center">
//                         <Image className='imgCard' style={{ borderRadius: '5px 5px 0px 0px' }} 
//                                src={process.env.REACT_APP_API_URL + product.img} 
                               
//                                alt={product.name} /> {/* Добавил alt для доступности */}
                            
//                     </div>
//                     <div className="p-2 text-center d-flex flex-column justify-content-between" style={{ height: '100px' }}>
//                         <div className="product-name">{product.name}</div>
//                         <div className="d-flex align-items-center justify-content-between mt-2">
//                             <div className="product-price">{product.price} руб.</div>
//                             <div onClick={handleAddToCart} className="add-to-cart-icon">
//                                 {addedToCart ? (
//                                     <CiCircleCheck className="icon icon-rotate" size={24} />
//                                 ) : (
//                                     <Image width={18} height={18} src={basket} alt="Добавить в корзину" />
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </Card>
//             </Col>

//             {show && (
//                 <ProductPage
//                     productId={product.id}
//                     show={show}
//                     onHide={handleClose}
//                 />
//             )}
//         </>
//     );
// };

// export default ProductItem;


import React, { useContext, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CiCircleCheck } from "react-icons/ci";
import basket from '../photoNvabar/Navigation icon/basket black.png';
import photo_time from '../photoNvabar/8bf4cea1-5f55-43ad-b3a2-4f3b07e9ccad.jpg'
import '../css/ProductItem.css';
import { CartContext } from '../components/CartContext';
import ProductPage from '../pages/ProductPage';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductItem = ({ product }) => {
    const [show, setShow] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);
    const navigate = useNavigate();
    const { addToCart } = useContext(CartContext);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    
    const handleAddToCart = (e) => {
        e.stopPropagation();
        addToCart(product);
        setAddedToCart(true);
        setTimeout(() => setAddedToCart(false), 2000);
    };

    return (
        <>
            <Col md={3} className='d-flex justify-content-around'>
                <Card style={{ width: 250, cursor: 'pointer' }} className="mb-3" onClick={handleShow}>
                    <div className="d-flex justify-content-center">
                        <LazyLoadImage
                            className='imgCard'
                            style={{ borderRadius: '5px 5px 0px 0px' }}
                            src={process.env.REACT_APP_API_URL + product.img}
                            alt={product.name}
                              // Плавное появление
                            width="100%"
                            height="200px"
                            placeholderSrc={photo_time}
                        />
                    </div>
                    <div className="p-2 text-center d-flex flex-column justify-content-between" style={{ height: '100px' }}>
                        <div className="product-name">{product.name}</div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="product-price">{product.price} руб.</div>
                            <div onClick={handleAddToCart} className="add-to-cart-icon">
                                {addedToCart ? (
                                    <CiCircleCheck className="icon icon-rotate" size={24} />
                                ) : (
                                    <img width={18} height={18} src={basket} alt="Добавить в корзину" />
                                )}
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>

            {show && (
                <ProductPage
                    productId={product.id}
                    show={show}
                    onHide={handleClose}
                />
            )}
        </>
    );
};

export default ProductItem;
